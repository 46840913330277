export const config = {
  // this value can only be used on mobile app
  proMainPage: process.env.EXPO_PUBLIC_WEB_PRO_CLIENT_URL,
  help: {
    url: 'https://help.medely.com',
    setYourselfUpForSuccess:
      'https://help.medely.com/en/articles/6971077-professional-how-to-set-yourself-up-for-success-at-medely',
    clockInClockOut: 'https://help.medely.com/en/articles/103312-clocking-in-out',
    cancellationsAndNoShow: 'https://help.medely.com/en/articles/1871897-cancellation-policy',
    limitedTimeIncentiveProgram:
      'https://help.medely.com/en/articles/9736000-limited-time-incentive-program',
    learnAboutPayouts: 'https://help.medely.com/en/articles/9736094-learn-about-payouts',
    paymentsForTravelAssignments:
      'https://help.medely.com/en/articles/2712787-payments-for-travel-assignments',
    paymentsFor99Assignments:
      'https://help.medely.com/en/articles/5575666-payments-for-1099-assignments',
    paymentsForPerDiemShifts:
      'https://help.medely.com/en/articles/1214979-payments-for-per-diem-shifts',
  },
};
