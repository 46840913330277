import { Box, Label, Text } from '@medely/ui-kit';
import React from 'react';
import { IPosition } from '@medely/types';

type PositionItem = {
  title: string;
  text: string;
  key: string;
};

type PositionsDetailsProps = {
  positions: IPosition[];
};

const PositionItem = ({ title, text, key }: PositionItem) => {
  return (
    <Box my={2} key={key}>
      <Label size="l" color="text.primary">
        {title}
      </Label>
      <Text size="m" color="text.secondary">
        {text}
      </Text>
    </Box>
  );
};

export const PositionsDetails = ({ positions }: PositionsDetailsProps) => {
  return (
    <>
      {positions.map((position) => {
        return (
          <PositionItem
            key={position.id.toString()}
            title={position.display_name}
            text={position.lead_description}
          />
        );
      })}
    </>
  );
};
