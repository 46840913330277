import { BottomSheetDialog } from '@medely/web-components';
import React from 'react';
import { PositionsDetails } from './PositionsDetails';
import { Button } from '@medely/ui-kit';
import useDevice from '../../hooks/useDevice.web';
import { IPosition } from '@medely/types';

type BottomSheetDialogProps = React.ComponentProps<typeof BottomSheetDialog>;

export type PositionsDetailsModalProps = {
  open: BottomSheetDialogProps['open'];
  onClose: BottomSheetDialogProps['onClose'];
  positions: IPosition[];
};

export const PositionsDetailsModal = ({ open, onClose, positions }: PositionsDetailsModalProps) => {
  const isDesktop = useDevice() === 'desktop';
  return (
    <BottomSheetDialog
      open={open}
      onClose={onClose}
      title="Position details"
      disableRestoreFocus
      content={<PositionsDetails positions={positions} />}
      actions={
        <Button fullWidth onClick={onClose}>
          Close
        </Button>
      }
      isDesktop={isDesktop}
    />
  );
};
