import React from 'react';
import { Box, Button, Dialog, DialogProps } from '@medely/web-components';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import MissingConditionFormOptions from 'components/credentials/conditionDialogs/MissingConditionFormOptions';
import MissingConditionForm from 'components/credentials/conditionDialogs/MissingConditionForm';
import type { ICondition } from '@medely/web-components/types';

type CredentialUploadDialogProps = DialogProps & {
  condition: ICondition;
};

const MissingConditionFormDialog = ({ open, onClose, condition }: CredentialUploadDialogProps) => (
  <MissingConditionForm condition={condition} onQualificationsResolve={onClose}>
    {({ condition, isActionable, submit }) => (
      <Form
        onSubmit={submit}
        mutators={{ ...arrayMutators }}
        initialValues={{ condition }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Dialog
              maxWidth="sm"
              fullWidth
              open={open}
              onClose={onClose}
              title={isActionable ? 'Upload Missing Credential' : undefined}
              data-testid="missing-condition-form-dialog"
              content={<MissingConditionFormOptions />}
              actions={
                <Box display="flex" mt={2}>
                  {isActionable && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={submitting}
                      loading={submitting}
                      sx={{ ml: 2 }}
                      data-testid="missing-condition-form-dialog-next-button"
                    >
                      Next
                    </Button>
                  )}
                </Box>
              }
            />
          </form>
        )}
      </Form>
    )}
  </MissingConditionForm>
);

export default MissingConditionFormDialog;
