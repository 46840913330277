import { Alert } from '@medely/ui-kit';
import React, { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

export const HelpArticle = () => {
  const [{ url }] = useQueryParams({ url: StringParam });
  const getIntercomCookieSession = document.cookie.match(new RegExp('intercom-session-([^;]+)'));
  const validIntercomSession = Boolean(
    getIntercomCookieSession && getIntercomCookieSession[0].split('=')[1] !== '',
  );
  const canShowArticle = Boolean(url && validIntercomSession);

  useEffect(() => {
    if (canShowArticle) {
      window.location.replace(url);
    }
  }, [canShowArticle]);

  return canShowArticle ? null : (
    <Alert
      severity="error"
      variant="filled"
      title="Unable to open this link"
      description="Please try again."
      hideCloseIcon
    />
  );
};
