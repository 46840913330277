import React, { useEffect } from 'react';
import { Box, Heading, MultiSelectField, OptionType, VStack } from '@medely/ui-kit';
import { PositionsDetailsModalButton } from './components/PositionsDetailsModalButton';
import { IPosition } from '@medely/types';

import { SelectedPosition } from './types';
import { getRecommendedPositions } from './util';

type RecommendedPositionsProps = {
  allPositions: IPosition[];
  initialSubmittedPositions: SelectedPosition[];
};

export const RecommendedPositions = ({
  allPositions,
  initialSubmittedPositions,
}: RecommendedPositionsProps) => {
  const [recommendedPositionOptions, setRecommendedPositionOptions] = React.useState<OptionType[]>(
    [],
  );

  useEffect(() => {
    if (!recommendedPositionOptions.length && allPositions.length) {
      setRecommendedPositionOptions(
        getRecommendedPositions(allPositions, initialSubmittedPositions),
      );
    }
  }, [allPositions]);

  const detailsPositions = allPositions
    .filter((position) => recommendedPositionOptions.some((option) => option.value === position.id))
    .sort((a, b) => a.display_name.localeCompare(b.display_name));

  return (
    <Box>
      <Heading size="s" color="text.primary">
        Select related positions to increase your earnings potential
      </Heading>
      <PositionsDetailsModalButton positions={detailsPositions} />
      <VStack gap={2}>
        <MultiSelectField
          variant="list"
          options={recommendedPositionOptions}
          label="Recommended Positions"
          name="professional_positions"
          parseValue={(value) => value.positions.map((position: SelectedPosition) => position.id)}
          formatOnChange={(newValue, fieldValue) => {
            const newObject = { ...fieldValue };
            newValue.forEach((value: number) => {
              if (
                !newObject.positions.some((position: SelectedPosition) => position.id === value)
              ) {
                newObject.positions.push({
                  id: value,
                  years_of_experience: undefined,
                  specialty_ids: [],
                });
              }
            });
            newObject.positions = newObject.positions.filter(
              (position: SelectedPosition) =>
                newValue.includes(position.id) ||
                initialSubmittedPositions.some((pos) => pos.id === position.id),
            );
            return newObject;
          }}
        />
      </VStack>
    </Box>
  );
};
