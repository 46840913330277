import { getJobsWithoutPayoutQuery } from '../graphql/jobsQueries';
import { GET_UNPAID_TIMECARD_ADJUSTMENTS } from '../graphql/expenseAdjustmentQueries';
import { useQuery } from '@tanstack/react-query';
import { useCurrentUser } from './useCurrentUser';
import useGraphQLRequest from './useGraphQLRequest';
import { EarningsPendingAdjustment, EarningsPendingJob } from '../components/earnings/interface';
import { UNPAID_ADJUSTMENT_JOB_STATUSES } from '../constants/earnings';
import { isEmpty } from 'lodash';

type IUsePendingEarningsReturn = {
  jobs: EarningsPendingJob[];
  adjustments: EarningsPendingAdjustment[];
  isLoading: boolean;
  hasPendingEarnings: boolean;
};

export const usePendingEarnings = (onError: (e: Error) => void): IUsePendingEarningsReturn => {
  const { request } = useGraphQLRequest();
  const { currentUser } = useCurrentUser();
  const jobsWithoutPayoutsQuery = getJobsWithoutPayoutQuery(currentUser);

  const fetchJobsWithoutPayouts = async () => {
    const { jobsWithoutPayout } = await request(jobsWithoutPayoutsQuery, {
      input: {
        search: {
          statuses: [
            'clocked_out',
            'completed',
            'canceled_with_fee',
            'held_for_dispute_review',
            'disputed',
          ],
        },
        orderBy: {
          starts_time: true,
        },
      },
    });
    return jobsWithoutPayout;
  };

  const { data: jobsData, isLoading: areJobsLoading } = useQuery(
    ['jobsWithoutPayouts'],
    fetchJobsWithoutPayouts,
    { onError },
  );
  const filteredJobs = ((jobsData ?? []) as IUsePendingEarningsReturn['jobs'])
    .map((job) => {
      // Get job_billings and expense_adjustments that *will be* paid
      // Only job_billings with a make_payable_at date are paid
      // expense_adjustments are only paid if the job was worked, exclude other statuses.
      // Remove job_billings and expense_adjustments that have already been paid (have a payout).
      return {
        ...job,
        job_billings: job.job_billings.filter((jb) => !jb.payout && !!jb.make_payable_at),
        expense_adjustments: UNPAID_ADJUSTMENT_JOB_STATUSES.includes(job.status)
          ? []
          : job.expense_adjustments.filter((adj) => !adj.payout),
      };
    })
    .filter((job) => job.job_billings.length > 0 || job.expense_adjustments.length > 0);

  const fetchUnpaidTimecardAdjustments = async () => {
    const { unpaidTimecardAdjustments } = await request(GET_UNPAID_TIMECARD_ADJUSTMENTS);

    return unpaidTimecardAdjustments;
  };

  const { data: adjustmentsData, isLoading: areAdjustmentsLoading } = useQuery(
    ['unpaidTimecardAdjustments'],
    fetchUnpaidTimecardAdjustments,
    { onError },
  );

  const adjustments = adjustmentsData ?? [];
  const hasPendingEarnings = !isEmpty(filteredJobs) || !isEmpty(adjustments);

  return {
    jobs: filteredJobs,
    adjustments,
    isLoading: areJobsLoading || areAdjustmentsLoading,
    hasPendingEarnings,
  };
};

export default usePendingEarnings;
