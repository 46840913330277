import React from 'react';
import { Box, Heading, MultiSelectField, VStack } from '@medely/ui-kit';
import { PositionsDetailsModalButton } from './components/PositionsDetailsModalButton';
import { IPosition } from '@medely/types';
import { SelectedPosition } from './types';

type PositionsSelectProps = {
  allPositions: IPosition[];
};

export const PositionsSelect = ({ allPositions }: PositionsSelectProps) => {
  const options = allPositions.map((position) => {
    return { value: position.id, label: position.display_name };
  });

  return (
    <Box>
      <Heading size="s" color="text.primary">
        Select positions with 8+ months of paid clinical experience
      </Heading>
      <PositionsDetailsModalButton positions={allPositions} />
      <VStack gap={2}>
        <MultiSelectField
          variant="list"
          options={options}
          label="Selected Positions"
          name="professional_positions"
          parseError={(value) => value.positions}
          parseValue={(value) => {
            return value.positions.map((position: SelectedPosition) => position.id);
          }}
          formatOnChange={(newValue, fieldValue) => {
            const newObject = { ...fieldValue };
            newValue.forEach((value: number) => {
              if (
                !newObject.positions.some((position: SelectedPosition) => position.id === value)
              ) {
                newObject.positions.push({
                  id: value,
                  years_of_experience: undefined,
                  specialty_ids: [],
                });
              }
            });
            newObject.positions =
              newObject.positions.filter((position: SelectedPosition) =>
                newValue.includes(position.id),
              ) || [];
            return newObject;
          }}
        />
      </VStack>
    </Box>
  );
};
