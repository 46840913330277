import { IPosition } from '@medely/types';
import { GET_ALL_POSITIONS } from '../graphql';
import { useCurrentUser } from './useCurrentUser';
import { useQuery } from '@tanstack/react-query';
import useGraphQLRequest from './useGraphQLRequest';

interface IUsePositionsReturn {
  positions: IPosition[];
  isLoading: boolean;
}

const useAllPositions = (allKindsPositions = false): IUsePositionsReturn => {
  const { request } = useGraphQLRequest();
  const { currentUser } = useCurrentUser();
  const enabled = !!currentUser?.professional?.kinds;
  const filterByKinds = allKindsPositions ? undefined : currentUser?.professional?.kinds;

  const fetchPositions = async ({ queryKey: [_entity, kinds] }: { queryKey: any }) => {
    const { positions } = await request(GET_ALL_POSITIONS, {
      input: { filter: { active: true }, search: { kinds }, orderBy: { display_name: true } },
    });
    return positions;
  };

  const { data, isLoading, isInitialLoading } = useQuery(
    ['positions', filterByKinds],
    fetchPositions,
    {
      enabled,
    },
  );

  return {
    positions: data ?? [],
    isLoading: enabled ? isLoading : isInitialLoading,
  };
};

export default useAllPositions;
