import React, { Fragment } from 'react';
import moment from 'moment';
import { capitalize } from 'core/utils/text';
import { InfoBadge, Text, Label, Heading } from '@medely/ui-kit';
import { Box, Card, Divider } from '@medely/web-components';
import { ChevronRight } from '@medely/web-components/icons';
import ConditionFormFields from 'components/credentials/ConditionFormFields';
import { useMissingConditionForm } from 'components/credentials/conditionDialogs/MissingConditionForm';
import useNearFutureExpiringConditions from 'hooks/useNearFutureExpiringConditions';
import useProfessionalQualifications from 'hooks/useProfessionalQualifications';
import type { MissingConditionOption } from 'components/credentials/conditionDialogs/useMissingConditionOptions';
import type { ICondition, IQualification } from '@medely/types';

interface IMissingConditionFormOptionProps {
  label?: string;
  option: MissingConditionOption;
  onClick: (condition: ICondition, qualification: IQualification) => void;
}

interface IMissingSingleConditionQualificationStatus {
  option: MissingConditionOption;
  qualification: IQualification;
}

interface IMissingConditionFormOptionsPickerProps {
  condition: ICondition;
  options: MissingConditionOption[];
  onConditionSelect?: (condition?: ICondition, qualification?: IQualification) => void;
}

const MissingSingleConditionQualificationStatus = ({
  option,
  qualification,
}: IMissingSingleConditionQualificationStatus) => {
  const { expiringConditions } = useNearFutureExpiringConditions();
  const { allProfessionalQualifications } = useProfessionalQualifications();
  const isExpiringSoon = expiringConditions.some(
    (expiringCondition) => expiringCondition.slug === qualification.slug,
  );

  if (isExpiringSoon) {
    const conditionQualificationsIds = option.condition.qualifications.map(
      (qualification) => qualification.id,
    );
    const professionalQualifications = allProfessionalQualifications.filter((qualification) =>
      conditionQualificationsIds.includes(qualification.qualification.id),
    );
    const date = professionalQualifications[0]?.professional_qualification_attributes?.find(
      (attribute) => attribute.qualification_attribute?.field_type === 'expiration_date',
    )?.value;
    const expirationDate = date ? moment(date).format('M/D/YY') : 'soon';
    return <InfoBadge label={`Expires ${expirationDate}`} color="warning" />;
  }

  const status = option.qualificationStatus[qualification.id];
  if (status === 'approved') {
    return <InfoBadge color="success" label="Approved" />;
  }
  if (status === 'rejected') {
    return <InfoBadge color="error" label="Rejected" />;
  }
  if (status === 'expired') {
    return <InfoBadge color="error" label="Expired" />;
  }
  if (option.willBeSatisfied || status === 'review') {
    return <InfoBadge color="info" label="Under Review" />;
  }
  return null;
};

const MissingSingleConditionFormOption = ({
  label,
  option,
  onClick,
}: IMissingConditionFormOptionProps) => {
  const [qualification] = option.condition.qualifications;
  const title = qualification?.long_name || option.description;

  const handleClick = () => {
    onClick(option.condition, qualification);
  };

  return (
    <Card>
      <Box p={2} sx={{ cursor: 'pointer' }} onClick={handleClick}>
        {label && (
          <Box mb={1}>
            <Label size="xs" color="state.teal.secondary">
              {label}
            </Label>
          </Box>
        )}
        <Box display="flex" alignItems="center" gap={2}>
          <Box flex={1} display="flex" flexDirection="column" gap={0.25}>
            <Label size="m">{capitalize(title)}</Label>
            {qualification.description && (
              <Text size="m" color="text.secondary">
                {qualification.description}
              </Text>
            )}
          </Box>
          <MissingSingleConditionQualificationStatus
            option={option}
            qualification={qualification}
          />
          <ChevronRight color="disabled" />
        </Box>
      </Box>
    </Card>
  );
};

const MissingMultipleConditionFormOption = ({
  label,
  option,
  onClick,
}: IMissingConditionFormOptionProps) => {
  const hasMultipleQualifications = true;

  const handleClick = (qualification?: IQualification) => {
    onClick(option.condition, qualification || option.condition.qualifications[0]);
  };

  return (
    <Card>
      <Box p={2} pb={1}>
        {label && (
          <Box mb={1}>
            <Label size="xs" color="state.teal.secondary">
              {label}
            </Label>
          </Box>
        )}
        <Box>
          {option.condition.qualifications.map((qualification, index) => (
            <Fragment key={String(qualification.id)}>
              {index > 0 && <Divider />}
              <Box
                pb={1.5}
                pt={index > 0 ? 1.5 : 1}
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleClick(qualification)}
              >
                <Box
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap={0.5}
                >
                  <Label size="m">{qualification.long_name}</Label>
                  {qualification.description && (
                    <Text size="m" color="text.secondary">
                      {qualification.description}
                    </Text>
                  )}
                </Box>
                <MissingSingleConditionQualificationStatus
                  option={option}
                  qualification={qualification}
                />
                {hasMultipleQualifications && <ChevronRight color="disabled" />}
              </Box>
            </Fragment>
          ))}
        </Box>
      </Box>
    </Card>
  );
};

const MissingConditionFormOptionsPicker = ({
  condition,
  options,
  onConditionSelect,
}: IMissingConditionFormOptionsPickerProps) => {
  return (
    <Box>
      <Heading size="m">{condition.label}</Heading>
      <Box mt={0.5}>
        <Text size="m">Complete one of the following options.</Text>
      </Box>
      {options.map((option, index) => (
        <Box key={index} mt={3}>
          {option.condition.qualifications.length > 1 ? (
            <MissingMultipleConditionFormOption
              label={`Option ${index + 1}`}
              option={option}
              onClick={(_, qualification) => onConditionSelect?.(option.condition, qualification)}
            />
          ) : (
            <MissingSingleConditionFormOption
              label={`Option ${index + 1}`}
              option={option}
              onClick={() => onConditionSelect?.(option.condition)}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

const MissingConditionFormOptions = () => {
  const {
    condition,
    selectedCondition,
    selectedQualification,
    options,
    selectedQualificationIndex,
    selectCondition,
  } = useMissingConditionForm();
  const qualifications = selectedQualification ? [selectedQualification] : [];

  if (!options.length) {
    return null;
  }

  return (
    <>
      {options.length > 1 && !selectedCondition ? (
        <MissingConditionFormOptionsPicker
          condition={condition}
          options={options}
          onConditionSelect={selectCondition}
        />
      ) : (
        <ConditionFormFields
          name={`condition.qualifications[${selectedQualificationIndex}]`}
          condition={{
            ...selectedCondition,
            qualifications,
          }}
          withQualificationName
        />
      )}
    </>
  );
};

export default MissingConditionFormOptions;
